import React from 'react';

import {Painter} from 'components/Paint/Painter/Painter';

export const SandboxPainter = () => {
  return (
    <div className="sandbox-page">
      <div className="sandbox-body">
        <Painter />
      </div>
    </div>
  );
};
