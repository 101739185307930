import React, {useCallback, useState} from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import {type Descendant, Node} from 'slate';
import {withHistory} from 'slate-history';

import {SlateEditor} from 'components/SlateJS/components/SlateEditor';
import {
  fontSizes,
  type SlatePlugin,
  textColors,
  textFormats,
  textHighlights,
  withFormat,
  withIcons,
  withExample,
  withLink,
  withList
} from 'components/SlateJS/plugins';

import {testIntegralsValue} from '../../components/SlateJS/utils/temp/integrals';

const plugins: SlatePlugin[] = [
  withHistory,
  withFormat([...textFormats, ...textColors, ...textHighlights, ...fontSizes]),
  withIcons(),
  withLink(),
  withList(),
  withExample()
];

const toolbar = {portalId: 'sandbox-slate-toolbar-portal'};

export const SandboxSlate = () => {
  const [formatValue, setFormatValue] = useState<Descendant[]>(testIntegralsValue);
  const onChangeFormatValue = useCallback((value: Descendant[]) => {
    setFormatValue(value);
  }, []);

  return (
    <div className="sandbox-page">
      <div
        className="sandbox-body"
        style={{
          position: 'relative',
          overflow: 'initial',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{overflow: 'scroll', padding: '50px 20px 20px'}}>
          <FormGroup>
            <SlateEditor
              placeholder="Enter text here!"
              onChange={onChangeFormatValue}
              value={formatValue}
              plugins={plugins}
              toolbar={toolbar}
            />
            <HelpBlock bsClass="text-primary">
              {Node.string({children: formatValue} as Node)}
              <pre style={{maxHeight: '100px', fontSize: '8px'}}>
                {JSON.stringify(formatValue, null, 2)}
              </pre>
            </HelpBlock>
          </FormGroup>
        </div>
        <div
          id="sandbox-slate-toolbar-portal"
          style={{
            // minHeight: '30px',
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundColor: '#fff',
            order: -1,
            zIndex: 0,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)'
          }}
        />
      </div>
    </div>
  );
};
