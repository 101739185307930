import React, {type FC} from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import ToastrPanel from '../components/ToastrPanel';
import LocaleSelector from '../../../i18n/containers/LanguageDropdown';
import languages from '../../../i18n/languages';
import AutobahnTester from '../AutobahnTester';
import Md5FileHash from '../components/Md5FileHash';
import {SentryTest} from '../components/SentryTest';
import Forms from '../Forms';
import GradeWrapper from '../GradeWrapper';
import {ToggleSlateDevTools} from './ToggleSlateDevTools';
import {ToggleTouchEmulator} from './ToggleTouchEmulator';
import {FloatSidebar} from '../../../components/FloatSidebar/FloatSidebar';
import {CollapsedSidebarButton} from '../../../components/FloatSidebar/CollapsedSidebarButton';
import {I18nPanel} from '../components/I18nPanel';

export const Sandbox: FC = () => {
  return (
    <div className="sandbox-page">
      <div className="sandbox-body" style={{padding: 10}}>
        <PanelGroup id="sandbox-panels" accordion={true} defaultActiveKey="1">
          <Panel eventKey="1">
            <Panel.Heading>
              <Panel.Title toggle={true}>Test Autobahn</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <AutobahnTester />
            </Panel.Body>
          </Panel>
          <Panel eventKey="2">
            <Panel.Heading>
              <Panel.Title toggle={true}>Test components</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <LocaleSelector languages={languages} />
              <div>
                <ToastrPanel />
              </div>
              <div>
                <Md5FileHash />
              </div>
              <div>
                <SentryTest />
              </div>
              <GradeWrapper />
            </Panel.Body>
          </Panel>
          <Panel eventKey="3">
            <Panel.Heading>
              <Panel.Title toggle={true}>Forms</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <Forms />
            </Panel.Body>
          </Panel>
          <Panel eventKey="4">
            <Panel.Heading>
              <Panel.Title toggle={true}>Slate</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <ul>
                <li>
                  <Link to="/sandbox/slate">SlateJS Editor</Link>
                </li>
                <li>
                  <Link to="/sandbox/slate/old">Slate Editor (Old)</Link>
                </li>
                <li>
                  <Link to="/sandbox/slate/compare">Slate Compare Editors</Link>
                </li>
              </ul>
            </Panel.Body>
          </Panel>
          <Panel eventKey="5">
            <Panel.Heading>
              <Panel.Title toggle={true}>Touch Emulator</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <ToggleTouchEmulator />
            </Panel.Body>
          </Panel>
          <Panel eventKey="6">
            <Panel.Heading>
              <Panel.Title toggle={true}>i18n</Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible={true}>
              <I18nPanel />
            </Panel.Body>
          </Panel>
        </PanelGroup>
      </div>
      <FloatSidebar
        collapsedButton={(clickHandler, sidebarCollapsed) => (
          <CollapsedSidebarButton
            onClick={clickHandler}
            shouldBeRendered={sidebarCollapsed || false}
          />
        )}
      >
        <Panel eventKey="5">
          <Panel.Heading>
            <Panel.Title toggle={false}>Common Tools</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible={false}>
            <ToggleSlateDevTools />
            <ToggleTouchEmulator />
            <b>
              <FormattedMessage id="Common.Class" />:{' '}
            </b>
            <LocaleSelector languages={languages} />
          </Panel.Body>
        </Panel>
      </FloatSidebar>
    </div>
  );
};
