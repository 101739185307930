export function downloadUri(uri: string, name: string) {
  const link: HTMLAnchorElement | undefined = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadBlob(blob: Blob, name: string) {
  const link: HTMLAnchorElement | undefined = document.createElement('a');
  const uri = window.URL.createObjectURL(blob);
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(uri);
}
