import {type Action} from 'redux';
import {
  type ICallOptions,
  type IEvent,
  type IPublishOptions,
  type ISubscribeOptions
} from 'autobahn';

import {
  type WampAction as IWampAction,
  type WampCallAction,
  type WampPublishAction,
  type WampSubscribeAction,
  type WampUnsubscribeAction
} from 'services/wamp/actions/interface';
import {type WampActionType} from 'store/interface';
import {INITIALIZE_CLIENT, wampClientActionType} from 'services/wamp/actions/actionTypes';

import {
  ACTIVATED_TEST_ACTION,
  CLEAN_RESPONSE,
  CLEAR_AUTOBAHN_TESTER_STATE,
  TOPIC_EVENT,
  UPDATE_TOKEN,
  WAMP_TEST_ACTION
} from './actionTypes';

export interface UpdateTokenAction extends Action {
  token: string;
}

export interface ActiveTestAction extends Action {
  active: WampActionType;
}

export interface TopicEventAction extends Action {
  topic: string;
  args: {};
  kwargs: {};
  details: IEvent;
}

export interface InitializeClientAction extends IWampAction {
  url: string;
}

type InitializeClientActionCreator = (url: string) => InitializeClientAction;

export interface ClearAutobahnTesterStateAction extends Action {}

function topicEvent(topic: string, args: {}, kwargs: {}, details: IEvent): TopicEventAction {
  return {
    type: TOPIC_EVENT,
    topic,
    args,
    kwargs,
    details
  };
}

export type UpdateTokenCreator = (token: string) => UpdateTokenAction;

export type SendActionCreator = (
  action: WampActionType,
  uri: string,
  args?: Array<{}>,
  kwargs?: object,
  options?: IPublishOptions | ISubscribeOptions | ICallOptions
) => Action;

export const sendAction: SendActionCreator = (action, uri, args, kwargs, options) => {
  switch (action) {
    case 'call':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const callAction: WampCallAction<any, any> = {
        client: 'autobahn',
        type: WAMP_TEST_ACTION,
        wamp: {
          method: action,
          uri,
          args,
          kwargs,
          options: options as ICallOptions
        }
      };
      return callAction;
    case 'subscribe':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subscribeAction: WampSubscribeAction<any, any> = {
        client: 'autobahn',
        type: WAMP_TEST_ACTION,
        wamp: {
          method: action,
          uri,
          options: options as ISubscribeOptions
        },
        callback: (callbackArgs, callbackKwargs, details, api, subscriptionUri) => {
          api.dispatch(topicEvent(subscriptionUri, callbackArgs, callbackKwargs, details));
        }
      };
      return subscribeAction;
    case 'publish':
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const publishAction: WampPublishAction<any, any> = {
        client: 'autobahn',
        type: WAMP_TEST_ACTION,
        wamp: {
          method: action,
          uri,
          args,
          kwargs,
          options: options as IPublishOptions
        }
      };
      return publishAction;
    case 'unsubscribe':
      const unsubscribeAction: WampUnsubscribeAction = {
        client: 'autobahn',
        type: WAMP_TEST_ACTION,
        wamp: {
          method: action,
          uri
        }
      };
      return unsubscribeAction;
    default:
      throw new Error(`Action ${action} not supported`);
  }
};

export function activateTestActionCreator(active: WampActionType): ActiveTestAction {
  return {
    type: ACTIVATED_TEST_ACTION,
    active
  };
}

export function updateToken(token: string): UpdateTokenAction {
  return {
    type: UPDATE_TOKEN,
    token
  };
}

export function cleanResponse(): Action {
  return {
    type: CLEAN_RESPONSE
  };
}

export const clearAutobahnTesterState = (): ClearAutobahnTesterStateAction => ({
  type: CLEAR_AUTOBAHN_TESTER_STATE
});

export const initializeTesterClient: InitializeClientActionCreator = (url: string) => ({
  client: 'autobahn',
  type: wampClientActionType(INITIALIZE_CLIENT, 'autobahn'),
  url
});
