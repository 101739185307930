import {type Action, type Reducer} from 'redux';

import {type AutobahnTesterState} from 'store/interface';
import {
  type WampCallResponseAction,
  type WampErrorAction,
  type WampPublishResponseAction,
  type WampSubscribeResponseAction,
  type WampUnsubscribeResponseAction
} from 'services/wamp/actions/interface';

import {
  ACTIVATED_TEST_ACTION,
  CLEAN_RESPONSE,
  CLEAR_AUTOBAHN_TESTER_STATE,
  TOPIC_EVENT,
  UPDATE_TOKEN,
  WAMP_TEST_ACTION_FAIL,
  WAMP_TEST_ACTION_SUCCESS
} from '../actions/actionTypes';
import {
  type ActiveTestAction,
  type ClearAutobahnTesterStateAction,
  type TopicEventAction,
  type UpdateTokenAction
} from '../actions/actions';

interface ReducersMapObject<S> {
  [key: string]: Reducer<S>;
}

const initialWampTestState: AutobahnTesterState = {
  active: 'call',
  token: '',
  topics: {}
};

const reducers: ReducersMapObject<AutobahnTesterState> = {
  [CLEAN_RESPONSE]: (state: AutobahnTesterState, action: Action) => {
    const {response, ...rest} = state;
    return {...rest};
  },
  [TOPIC_EVENT]: (state: AutobahnTesterState, action: TopicEventAction) => {
    const {args, kwargs, details} = action;
    const {topics, ...rest} = state;
    const newTopics = {...topics};
    newTopics[action.topic].push({
      created: new Date(),
      json: JSON.stringify(
        {
          args,
          kwargs,
          details
        },
        null,
        2
      )
    });
    return {
      ...rest,
      topics: {...topics}
    };
  },
  [ACTIVATED_TEST_ACTION]: (state: AutobahnTesterState, action: ActiveTestAction) => {
    const {active} = action;
    return {
      ...state,
      active
    };
  },
  [WAMP_TEST_ACTION_SUCCESS]: (
    state: AutobahnTesterState,
    action:
      | WampCallResponseAction<{}, {}, {}>
      | WampSubscribeResponseAction<{}>
      | WampPublishResponseAction
      | WampUnsubscribeResponseAction
  ) => {
    if ((action as WampCallResponseAction<{}, {}, {}>).wamp.callResult) {
      return {
        ...state,
        response: JSON.stringify(
          (action as WampCallResponseAction<{}, {}, {}>).wamp.callResult,
          null,
          2
        )
      };
    } else if (
      (action as WampUnsubscribeResponseAction).method &&
      (action as WampUnsubscribeResponseAction).method === 'unsubscribe'
    ) {
      const topics = {...state.topics};
      delete topics[(action as WampUnsubscribeResponseAction).wamp.uri];
      return {
        ...state,
        topics
      };
    } else if ((action as WampPublishResponseAction).wamp.publication) {
      return {
        ...state,
        response: JSON.stringify((action as WampPublishResponseAction).wamp.publication, null, 2)
      };
    } else if ((action as WampSubscribeResponseAction<{}>).wamp.subscription) {
      const {topics, ...rest} = state;
      const subscription = (action as WampSubscribeResponseAction<{}>).wamp.subscription;

      const newTopics = {...topics};
      newTopics[subscription.topic] = [];

      // exclude session to prevent circular json object error
      const {session, ...response} = (action as WampSubscribeResponseAction<{}>).wamp.subscription;
      return {
        ...rest,
        topics: newTopics,
        response: JSON.stringify(response, null, 2)
      };
    }
    return {...state};
  },
  [WAMP_TEST_ACTION_FAIL]: (state: AutobahnTesterState, action: WampErrorAction<{}, {}, {}>) => {
    return {
      ...state,
      response: JSON.stringify(action.error, null, 2)
    };
  },
  [UPDATE_TOKEN]: (state: AutobahnTesterState, action: UpdateTokenAction) => {
    const {token} = action;
    return {
      ...state,
      token
    };
  },
  [CLEAR_AUTOBAHN_TESTER_STATE]: (
    state: AutobahnTesterState,
    action: ClearAutobahnTesterStateAction
  ) => {
    const {topics, response, ...newState} = state;
    return {
      ...newState,
      topics: {}
    };
  }
};

export default function (
  state: AutobahnTesterState = initialWampTestState,
  action: Action
): AutobahnTesterState {
  const wampReducer = reducers[action.type];
  return wampReducer ? wampReducer(state, action) : state;
}
