import React from 'react';
import Button from 'react-bootstrap/lib/Button';

import md5Chunk from 'services/common-methods/md5chunk';

interface Props {}

interface State {
  hashes: {[key: string]: string};
}

class Md5FileHash extends React.Component<Props, State> {
  public state: State = {
    hashes: {}
  };

  private input: HTMLInputElement | null;

  public render() {
    return (
      <div>
        <h3>File MD5 hashes:</h3>
        <input
          ref={input => (this.input = input)}
          multiple={true}
          onChange={e => {
            this.calcMd5Hash(e);
          }}
          type="file"
        />
        {this.renderFileHashes()}
        {this.renderClearButton()}
      </div>
    );
  }

  private calcMd5Hash(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      for (const index in e.target.files) {
        if (e.target.files.hasOwnProperty(index)) {
          const file = e.target.files[index];
          if (file) {
            md5Chunk(file).then(hash => {
              this.setState({
                hashes: {...this.state.hashes, [file.name]: hash}
              });
            });
          }
        }
      }
    }
  }

  private renderFileHashes() {
    const ordered = Object.keys(this.state.hashes)
      .sort()
      .reduce((r, k) => {
        r[k] = this.state.hashes[k];
        return r;
      }, {});
    return (
      <ul>
        {Object.keys(ordered).map((fileName, i) => {
          return <li key={i}>{`${fileName} - ${ordered[fileName]}`}</li>;
        })}
      </ul>
    );
  }

  private renderClearButton() {
    if (!Object.keys(this.state.hashes).length) {
      return null;
    }
    return (
      <Button bsStyle="danger" onClick={() => this.clearFileList()}>
        Clear List
      </Button>
    );
  }

  private clearFileList() {
    this.setState({hashes: {}});
    if (this.input && this.input.files && this.input.files.length) {
      this.input.value = '';
    }
  }
}

export default Md5FileHash;
