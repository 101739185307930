import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';

import ToastrContent from '../../../components/toastr/ToastrContent';

export default class ToastrPanel extends React.Component<{}, {}> {
  public render(): JSX.Element {
    const longString: string = 'Mirage is the second studio album';
    return (
      <div className="toastr-panel">
        Toastr test
        <ButtonGroup>
          <Button
            bsStyle="primary"
            onClick={() => {
              toastr.success('Success', 'This is success');
            }}
          >
            Success
          </Button>
          <button
            className="btn btn-success"
            onClick={() => {
              toastr.success('', 'No header here');
            }}
          >
            Success without header
          </button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            bsStyle="primary"
            onClick={() => {
              toastr.error('Error', 'This button is so good', {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                component: <ToastrContent reconnectButton={true} />,
                icon: <Icon name="warning" />,
                removeOnHover: false
              });
            }}
          >
            Error with button
          </Button>
          <button
            className="btn btn-success"
            onClick={() => {
              toastr.error('Error', 'This is error');
            }}
          >
            Error
          </button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            bsStyle="primary"
            onClick={() => {
              toastr.info('Info', 'This is info');
            }}
          >
            Info
          </Button>
          <button
            className="btn btn-success"
            onClick={() => {
              toastr.warning('hello', longString);
            }}
          >
            Warning
          </button>
        </ButtonGroup>
      </div>
    );
  }
}
