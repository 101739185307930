import React from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import {slateDevToolsEnabled, slateDevToolsLSKey} from 'components/Slate/utils/slateDevTools';

type Props = {};

interface State {
  devtoolsEnabled: boolean;
}

export class ToggleSlateDevTools extends React.Component<Props, State> {
  public state: State = {devtoolsEnabled: slateDevToolsEnabled()};

  public render() {
    return (
      <div>
        <FormGroup>
          <Checkbox onChange={this.toggle} checked={this.state.devtoolsEnabled}>
            <span>Slate Devtools Enabled</span>
          </Checkbox>
        </FormGroup>
      </div>
    );
  }

  private toggle = () => {
    if (localStorage.getItem(slateDevToolsLSKey) === 'true') {
      localStorage.removeItem(slateDevToolsLSKey);
    } else {
      localStorage.setItem(slateDevToolsLSKey, 'true');
    }
    this.setState({devtoolsEnabled: slateDevToolsEnabled()});
  };
}
