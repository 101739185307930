import {connect, type MapDispatchToProps} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {
  type AppState,
  CLOSED,
  CONNECTED,
  NOT_CONNECTED,
  type TestTopics,
  type WampActionType,
  type WampState,
  type WampStatus
} from 'store/interface';
import injectReducer from 'store/injectReducer';
import wampReducer from 'services/wamp/reducers/reducer';
import {wampConnectionClose, wampConnectionOpen} from 'services/wamp/actions/action';

import {
  activateTestActionCreator,
  cleanResponse,
  clearAutobahnTesterState,
  initializeTesterClient,
  sendAction,
  type SendActionCreator,
  updateToken,
  type UpdateTokenCreator
} from './actions/actions';
import autobahnTesterReducer from './reducers/autobahnTesterReducer';
import Autobahn from './components/AutobahnTester';

export interface StateProps {
  active: WampActionType;
  isChangeTokenDisabled: boolean;
  connected: boolean;
  status: WampStatus;
  response?: string;
  topics: TestTopics;
  userToken: string;
  clientState: WampState;
}

export interface DispatchProps {
  changeTestAction: (action: WampActionType) => void;
  disconnect: () => void;
  connect: (url: string) => void;
  sendAction: SendActionCreator;
  updateToken: UpdateTokenCreator;
}

const mapStateToProps = (state: AppState) => ({
  active: state.autobahnTester ? state.autobahnTester.active : 'call',
  status: state.autobahn!.status,
  isChangeTokenDisabled:
    state.autobahn!.status !== NOT_CONNECTED && state.autobahn!.status !== CLOSED,
  connected: state.autobahn!.status === CONNECTED,
  response: state.autobahnTester!.response,
  topics: state.autobahnTester!.topics ? state.autobahnTester!.topics : {},
  userToken:
    state.autobahnTester!.token.length !== 0 ? state.autobahnTester!.token : state.user.token!,
  clientState: state.autobahn!
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {connected: boolean}> = (
  dispatch: Dispatch<Action>
) => ({
  changeTestAction: (action: WampActionType) => dispatch(activateTestActionCreator(action)),
  disconnect: () => {
    dispatch(wampConnectionClose('autobahn'));
    dispatch(clearAutobahnTesterState());
  },
  connect: (url: string) => {
    dispatch(initializeTesterClient(url));
    dispatch(wampConnectionOpen('autobahn'));
  },
  sendAction: (action, uri, args, kwargs, options) => {
    dispatch(cleanResponse());
    return dispatch(sendAction(action, uri, args, kwargs, options));
  },
  updateToken: token => dispatch(updateToken(token))
});

export default injectReducer({
  autobahnTester: autobahnTesterReducer,
  autobahn: wampReducer('autobahn')
})(connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(Autobahn));
