import {Route, Routes} from 'react-router-dom';
import React from 'react';

import {Sandbox} from './containers/Sandbox';
import {PageNotFound} from '../PageNotFound/components/PageNotFound';
import {SandboxPainter} from './SandboxPainter';
import {SandboxMobilePainter} from './SandboxMobilePainter';
import {SandboxMobileDocPainter} from './SandboxMobileDocPainter';
import {SandboxYPainter} from './SandboxYPainter';
import {SandboxSimplePaint} from './SandboxSimplePaint';
import {SandboxSlate} from './SandboxSlate';
import {SandboxSlateCompare} from './SandboxSlateCompare';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import {SandboxSlateOld} from './SandboxSlateOld';
import {SandboxTestErrorBoundary} from './SandboxTestErrorBoundary';

import './SandboxRoute.scss';

const SandboxRoute = () => {
  return (
    <Routes>
      <Route path="mobile">
        <Route path="painter">
          <Route index={true} element={<SandboxMobilePainter />} />
          <Route path="doc" element={<SandboxMobileDocPainter />} />
        </Route>
      </Route>

      <Route element={<MainLayout />}>
        <Route index={true} element={<Sandbox />} />
        <Route path="error" element={<SandboxTestErrorBoundary />} />
        <Route path="painter" element={<SandboxPainter />} />
        <Route path="painter/:docId" element={<SandboxYPainter />} />
        <Route path="simple-paint" element={<SandboxSimplePaint />} />
        <Route path="slate">
          <Route index={true} element={<SandboxSlate />} />
          <Route path="old" element={<SandboxSlateOld />} />
          <Route path="compare" element={<SandboxSlateCompare />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
export default SandboxRoute;
