import React, {useMemo} from 'react';
import {type PStage} from '@englex/paint';
import {useImage} from '@englex/paint-react';

import {SimplePaint} from 'components/Paint/Simple/SimplePaint';
import Loader from 'components/Loader';

const createStage = (
  image: HTMLImageElement,
  devicePixelRatio: number = window.devicePixelRatio
): PStage => {
  const width = image.width / devicePixelRatio;
  const height = image.height / devicePixelRatio;
  return {
    className: 'Stage',
    attrs: {
      width,
      height
    },
    children: [
      {
        className: 'Layer',
        attrs: {},
        children: [
          {
            attrs: {
              image,
              scale: {x: 1 / devicePixelRatio, y: 1 / devicePixelRatio}
            },
            className: 'Image'
          }
        ]
      },
      {
        className: 'Layer',
        attrs: {},
        children: []
      }
    ]
  };
};

export const SandboxSimplePaint = () => {
  const [image, status] = useImage(
    'https://f5a2fe09-8fbf-4c58-afe4-b3a3941ebefe.selstorage.ru/image/68642/1582294158_just_cause_4_4k.jpg',
    'anonymous'
  );
  const loading = status !== 'loaded';
  const stage = useMemo(() => {
    if (loading || !image) return;
    return createStage(image);
  }, [image, loading]);

  if (loading || !stage) {
    return <Loader />;
  }
  return (
    <div className="sandbox-page">
      <div className="sandbox-body">
        <SimplePaint stage={stage} />
      </div>
    </div>
  );
};
