import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';
import './toastrContent.scss';

interface ToastrContentProps {
  reconnectButton?: boolean;
}

export default class ToastrContent extends React.Component<ToastrContentProps, {}> {
  public render(): JSX.Element {
    let button: JSX.Element | null;
    if (this.props.reconnectButton) {
      button = (
        <Button className="toastr-reconnect-btn">
          <FormattedMessage id="Toastr.ReconnectButtonText" />
        </Button>
      );
    } else {
      button = null;
    }
    return <div className="toastr-content">{button}</div>;
  }
}
