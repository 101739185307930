import React, {useCallback, useState} from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import {type Descendant, Node} from 'slate';
import {Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import {withHistory} from 'slate-history';

import {SlateEditor} from 'components/SlateJS/components/SlateEditor';
import {InlineInput} from 'components/SlateJS/components/InlineInput';
import EnlxSlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import {slateMigrateDown, slateMigrateUp} from 'components/SlateJS/utils';
import {testIntegralsValue} from 'components/SlateJS/utils/temp/integrals';
import {
  fontSizes,
  type SlatePlugin,
  textColors,
  textFormats,
  textHighlights,
  withExample,
  withFormat,
  withIcons,
  withLink,
  withList
} from 'components/SlateJS/plugins';

import {type SlateReactChange} from '../../components/Slate/interface';
import Undo from '../../components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from '../../components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from '../../components/Slate/SlateEditor/plugins/button/Bold';
import Italic from '../../components/Slate/SlateEditor/plugins/button/Italic';
import Underline from '../../components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from '../../components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from '../../components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from '../../components/Slate/SlateEditor/plugins/button/Highlight';
import CharSelector from '../../components/Slate/SlateEditor/plugins/button/CharSelector';
import Link from '../../components/Slate/SlateEditor/plugins/button/Link';
import FormatPainter from '../../components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from '../../components/Slate/SlateEditor/plugins/button/ClearFormatting';
import SimpleLists from '../../components/Slate/SlateEditor/plugins/button/List/SimpleLists';
import {ToggleExample} from '../../components/Slate/SlateEditor/plugins/button/Example/ToggleExample';

const plugins: SlatePlugin[] = [
  withHistory,
  withFormat([...textFormats, ...textColors, ...textHighlights, ...fontSizes]),
  withIcons(),
  withLink(),
  withList(),
  withExample()
];

const enlxPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new SimpleLists(),
  new Link(),
  new CharSelector(),
  new FormatPainter(),
  new ClearFormatting(),
  new ToggleExample()
];

const toolbar = {portalId: 'sandbox-slate-toolbar-portal'};

export const SandboxSlateCompare = () => {
  const [inlineText, setInlineText] = useState('Inline Input');
  const onInlineChange = useCallback((text: string) => setInlineText(text), []);

  const [formatValue, setFormatValue] = useState<Descendant[]>(testIntegralsValue());
  const onChangeFormatValue = useCallback((value: Descendant[]) => {
    setFormatValue(value);
    setEnlxSlateValue(Value.fromJSON(slateMigrateDown(value)));
  }, []);

  const [enlxSlateValue, setEnlxSlateValue] = useState(
    Value.fromJSON(slateMigrateDown(formatValue))
  );
  const onEnlxSlateChange = useCallback(({value}: SlateReactChange) => {
    setEnlxSlateValue(value);
    setFormatValue(slateMigrateUp(value.toJSON()));
  }, []);

  return (
    <div className="sandbox-page">
      <div
        className="sandbox-body"
        style={{
          position: 'relative',
          overflow: 'initial',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{overflow: 'scroll', padding: '20px'}}>
          <form action="">
            <FormGroup>
              <ControlLabel>InlineInput:</ControlLabel>
              <InlineInput
                text={inlineText}
                placeholder="Enter text here!"
                className="form-control"
                onChange={onInlineChange}
                style={{height: 'auto'}}
              />
              <HelpBlock bsClass="text-primary">{inlineText}</HelpBlock>
            </FormGroup>
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>SlateJS:</ControlLabel>
                  <SlateEditor
                    placeholder="Enter text here!"
                    onChange={onChangeFormatValue}
                    value={formatValue}
                    plugins={plugins}
                    toolbar={toolbar}
                  />
                  <HelpBlock bsClass="text-primary">
                    {Node.string({children: formatValue} as Node)}
                    <pre style={{maxHeight: '100px', fontSize: '8px'}}>
                      {JSON.stringify(formatValue, null, 2)}
                    </pre>
                  </HelpBlock>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <ControlLabel>@englex/slate:</ControlLabel>
                  <EnlxSlateEditor
                    toolbar={{portalId: 'sandbox-slate-toolbar-portal'}}
                    placeholder="Enter text here!"
                    onChange={onEnlxSlateChange}
                    value={enlxSlateValue}
                    plugins={enlxPlugins}
                  />
                  <HelpBlock bsClass="text-primary">
                    {enlxSlateValue.document.text}
                    <pre style={{maxHeight: '100px', fontSize: '8px'}}>
                      {JSON.stringify(enlxSlateValue.toJSON(), null, 2)}
                    </pre>
                  </HelpBlock>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </div>
        <div
          id="sandbox-slate-toolbar-portal"
          style={{
            // minHeight: '30px',
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundColor: '#fff',
            order: -1,
            zIndex: 0,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)'
          }}
        />
      </div>
    </div>
  );
};
