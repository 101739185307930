import React from 'react';
import {Portal} from 'react-portal';

import './GradeSlider.scss';

const {round} = Math;

interface Props {
  // properties
  initialGrade: number;
  maxGrade: number;
  showCurrentGrade: boolean;
  showHint: boolean;
  step: number;
  width: number;
  // methods
  onClick?: (value: number) => void;
  onDragEnd?: (value: number) => void;
  onStepClick?: (value: number) => void;
}

interface State {
  hintOffset?: number;
  hoverPosition?: number;
  mouseDown: boolean;
  value: number;
}

class GradeSlider extends React.Component<Props, State> {
  public static defaultProps = {
    initialGrade: 100,
    maxGrade: 100,
    showCurrentGrade: true,
    showHint: true,
    step: 10,
    width: 300
  };
  // 15 is half-width of hint div, its width is set to 30 px in scss
  private static hintDivHalfWidth: number = 15;
  private static percentage: number = 100;
  public state: State = {
    hintOffset: undefined,
    hoverPosition: undefined,
    mouseDown: false,
    value: this.props.initialGrade
  };
  private grade: HTMLDivElement | null;

  public render() {
    const {maxGrade, showCurrentGrade, showHint, width} = this.props;
    const {mouseDown, value} = this.state;
    const {Hint, ScrollHelper, Steps} = this;
    const currentGradeWidth = showCurrentGrade ? 50 : 0;
    return (
      <div className="grade-component" style={{width: `${width + currentGradeWidth}px`}}>
        {showCurrentGrade && (
          <div className="current-grade">
            <span>{value}</span>
          </div>
        )}
        <div className="grade-slider" style={{width: `${width}px`}}>
          <Steps />
          <div className="grade" onMouseMove={this.moveHandler} ref={this.getRef}>
            <div
              className={'grade-amount' + (mouseDown ? ' active' : '')}
              style={{
                width: (value / maxGrade) * GradeSlider.percentage + '%',
                backgroundSize: `${width}px`
              }}
            >
              <div
                className="grade-drag-circle"
                onMouseDown={this.dragCircleMouseDownHandler}
                style={{
                  backgroundColor: this.computeDragCircleColor(value / maxGrade)
                }}
              />
            </div>
            {mouseDown ? (
              <Portal node={document.querySelector('body')}>
                <ScrollHelper />
              </Portal>
            ) : (
              <ScrollHelper />
            )}
            {showHint && <Hint />}
          </div>
        </div>
      </div>
    );
  }

  private computeDragCircleColor = (position: number) => {
    if (position >= 0.67) {
      return '#417505';
    }
    if (position >= 0.34) {
      return '#e69500';
    }
    return '#b14b26';
  };

  private hideHint = () =>
    this.props.showHint && this.setState({hintOffset: undefined, hoverPosition: undefined});

  private dragCircleMouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    window.addEventListener('mouseup', this.mouseUpEvent);
    this.setState({mouseDown: true});
  };

  private getRef = (grade: HTMLDivElement | null) => {
    this.grade = grade;
  };

  private handleGradeChange = (e: React.MouseEvent<HTMLDivElement>, dragged?: true) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.grade) {
      const [{maxGrade, onClick}, sliderBounds] = [this.props, this.grade.getBoundingClientRect()];
      let value = round(((e.pageX - sliderBounds.left) / sliderBounds.width) * maxGrade);
      if (e.pageX < sliderBounds.left) {
        value = 0;
      } else if (e.pageX > sliderBounds.right) {
        value = round(maxGrade);
      } else {
        value = round(((e.pageX - sliderBounds.left) / sliderBounds.width) * maxGrade);
      }
      this.setState({value}, () => {
        !dragged && onClick && onClick(value);
      });
    }
  };

  private handleStepClick = (step: number) =>
    this.setState({value: step}, () => {
      this.props.onStepClick && this.props.onStepClick(step);
    });

  private moveHandler: (e: React.MouseEvent<HTMLDivElement>) => void = e => {
    e.preventDefault();
    this.state.mouseDown && this.handleGradeChange(e, true);
  };

  private mouseUpEvent = () => {
    const [{onDragEnd}, {value}] = [this.props, this.state];
    this.setState({mouseDown: false}, () => {
      window.removeEventListener('mouseup', this.mouseUpEvent);
      onDragEnd && onDragEnd(value);
    });
  };

  private updateHintProperties = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    const {maxGrade, showHint} = this.props;
    if (!showHint || !this.grade) {
      return;
    }
    const sliderBounds = this.grade.getBoundingClientRect();
    let hintOffset: number;
    let hoverPosition: number;
    if (e.pageX < sliderBounds.left) {
      [hintOffset, hoverPosition] = [-GradeSlider.hintDivHalfWidth, 0];
    } else if (e.pageX > sliderBounds.right) {
      [hintOffset, hoverPosition] = [sliderBounds.width - GradeSlider.hintDivHalfWidth, maxGrade];
    } else {
      hintOffset = e.pageX - sliderBounds.left - GradeSlider.hintDivHalfWidth;
      hoverPosition = round(((e.pageX - sliderBounds.left) / sliderBounds.width) * maxGrade);
    }
    this.setState({hoverPosition, hintOffset});
  };

  private Hint: React.FC = () => {
    const {hintOffset, hoverPosition} = this.state;
    return hoverPosition !== undefined && hintOffset ? (
      <div className="hint" style={{left: hintOffset}}>
        <span>{hoverPosition}</span>
      </div>
    ) : null;
  };

  private Steps: React.FC = () => {
    const [{width, maxGrade, step}, {value}] = [this.props, this.state];
    const steps: number[] = Array.from({length: round(maxGrade / step) + 1}, (v, i) => i * step);
    return (
      <div className="steps">
        {steps.map((s, i) => (
          <span
            key={s}
            className={'step' + (s === value ? ' active' : '')}
            onClick={() => this.handleStepClick(s)}
            style={{left: `${(i * width) / (steps.length - 1)}px`}}
          >
            {s}
          </span>
        ))}
      </div>
    );
  };

  private ScrollHelper: React.FC = () => (
    <div
      className={'grade-scroll-helper' + (this.state.mouseDown ? ' mouse-down' : '')}
      onClick={this.handleGradeChange}
      onMouseMove={this.updateHintProperties}
      onMouseLeave={this.hideHint}
    />
  );
}

export default GradeSlider;
