import React, {useMemo} from 'react';
import {type PaintPlugin} from '@englex/paint';
import {
  PaintTool,
  withBackgroundImage,
  withChildren,
  withColors,
  withFontSize,
  withKonvaFlags,
  withMobileViewport,
  withReact,
  withScrollBars,
  withSelectable,
  withThickness,
  withTools
} from '@englex/paint-react';
import {withHistory} from '@englex/paint-history';

import {Painter} from 'components/Paint/Painter/Painter';
import {isTouchEmulatorEnabled} from 'components/TouchEmulator';
import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import {withImage, withStoredState, withThicknessPreset} from 'components/Paint/Painter/plugins';
import {useMobilePainterAttrs} from 'components/Paint/hooks/useMobilePainterAttrs';

const defaultPlugins: PaintPlugin[] = [
  withReact(),
  withSelectable(),
  withHistory,
  withTools({active: PaintTool.Pencil}),
  withColors(),
  withThickness(),
  withThicknessPreset(),
  withFontSize(),
  withImage,
  withStoredState
];

const docUrl =
  'https://158305.selcdn.ru/vc-library-staging/document/Materials/PDF%20Materials/General%20English/Speakout%20Elementary/Workbook/Unit%208.%20Now.pdf';

export const SandboxMobileDocPainter = () => {
  const {attrs} = useMobilePainterAttrs(docUrl, 1);

  const plugins = useMemo(() => {
    if (attrs?.url) {
      return defaultPlugins.concat(
        withMobileViewport({...attrs.size, enabled: true}),
        withChildren({enabled: true}),
        withBackgroundImage({
          url: attrs.url
        }),
        withScrollBars(),
        withKonvaFlags({
          flags: {hitOnDragEnabled: true, capturePointerEventsEnabled: true},
          when: isTouchEmulatorEnabled
        })
      );
    }

    return defaultPlugins;
  }, [attrs]);

  return (
    <div className="sandbox-page">
      <div className="sandbox-body not-scrollable">
        {!attrs ? (
          <SimpleLoadingOverlay />
        ) : (
          <Painter
            plugins={plugins}
            width={attrs.size.width}
            height={attrs.size.height}
            orientation={attrs.orientation}
          />
        )}
      </div>
    </div>
  );
};
