import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import {type Locale} from 'store/interface';

import {
  type LanguageDropdownDispatchProps,
  type LanguageDropdownOwnProps,
  type LanguageDropdownStateProps
} from '../containers/LanguageDropdown';
import {type SelectCallback} from '../../components/interface';

export interface LanguageDropdownProps
  extends LanguageDropdownOwnProps,
    LanguageDropdownDispatchProps,
    LanguageDropdownStateProps {}

export default class LanguageDropDown extends React.Component<LanguageDropdownProps, {}> {
  public render(): JSX.Element {
    return (
      <DropdownButton
        id="langSelector"
        title={this.props.languages[this.props.locale]}
        onSelect={this.onSelect as SelectCallback}
      >
        {Object.keys(this.props.languages).map(this.renderOption.bind(this))}
      </DropdownButton>
    );
  }

  private onSelect = (eventKey: string) => {
    if (this.props.locale !== eventKey) {
      this.props.onChange(eventKey as Locale);
    }
  };

  private renderOption(locale: Locale): JSX.Element {
    return (
      <MenuItem key={locale} active={this.props.locale === locale} eventKey={locale}>
        {this.props.languages[locale]}
      </MenuItem>
    );
  }
}
