import React from 'react';
import {useParams} from 'react-router-dom';

import {YPainter} from 'components/Paint/YPainter';

export const SandboxYPainter = () => {
  const {docId} = useParams<{docId: string}>();

  return (
    <div className="sandbox-page">
      <div className="sandbox-body">
        <YPainter docId={docId!} />
      </div>
    </div>
  );
};
