const prefix = 'AUTOBAHN_TESTER/';

export const CLEAN_RESPONSE = prefix + 'CLEAN_RESPONSE';
export const ACTIVATED_TEST_ACTION = prefix + 'ACTIVATED_TEST_ACTION';
export const WAMP_TEST_ACTION = prefix + 'TEST_ACTION';
export const WAMP_TEST_ACTION_SUCCESS = prefix + 'TEST_ACTION_SUCCESS';
export const WAMP_TEST_ACTION_FAIL = prefix + 'TEST_ACTION_FAIL';
export const UPDATE_TOKEN = prefix + 'UPDATE_TOKEN';
export const TOPIC_EVENT = prefix + 'TOPIC_EVENT';
export const CLEAR_AUTOBAHN_TESTER_STATE = prefix + 'CLEAR_AUTOBAHN_TESTER_STATE';
