import React from 'react';
import {type PaintPlugin} from '@englex/paint';
import {
  PaintTool,
  withBackgroundImage,
  withChildren,
  withColors,
  withFontSize,
  withKonvaFlags,
  withMobileViewport,
  withReact,
  withScrollBars,
  withSelectable,
  withThickness,
  withTools
} from '@englex/paint-react';
import {withHistory} from '@englex/paint-history';

import {Painter} from 'components/Paint/Painter/Painter';
import {isTouchEmulatorEnabled} from 'components/TouchEmulator';

import {
  withImage,
  withStoredState,
  withThicknessPreset
} from '../../components/Paint/Painter/plugins';

const plugins: PaintPlugin[] = [
  withReact(),
  withSelectable(),
  withHistory,
  withTools({active: PaintTool.Pencil}),
  withColors(),
  withThickness(),
  withThicknessPreset(),
  withFontSize(),
  withImage,
  withStoredState,
  withMobileViewport({enabled: true}),
  withChildren({enabled: true}),
  withBackgroundImage({
    url: 'https://i.ibb.co/HCMffPJ/Screenshot-2022-08-19-at-12-34-37.png'
  }),
  withScrollBars(),
  withKonvaFlags({
    flags: {hitOnDragEnabled: true, capturePointerEventsEnabled: true},
    when: isTouchEmulatorEnabled
  })
];

export const SandboxMobilePainter = () => {
  return (
    <div className="sandbox-page">
      <div className="sandbox-body not-scrollable">
        <Painter plugins={plugins} width={3840} height={2160} />
      </div>
    </div>
  );
};
