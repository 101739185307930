import {type Descendant} from 'slate';

export function testIntegralsValue(): Descendant[] {
  return [
    {
      children: [
        {text: '∫', fontSize: 'largest', bold: true},
        {fontSize: 'largest', text: ' '},
        {fontSize: 'largest', text: 'sin', color: 'blue', italic: true},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: '·'},
        {fontSize: 'largest', text: 'cos', color: 'blue', italic: true},
        {fontSize: 'largest', text: '2x', bold: true},
        {fontSize: 'largest', text: ' '},
        {fontSize: 'largest', text: 'd', italic: true, bold: true},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: ' = -⅔'},
        {fontSize: 'largest', text: 'cos', color: 'blue', italic: true},
        {fontSize: 'largest', text: '³'},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: ' + '},
        {fontSize: 'largest', text: 'cos', color: 'blue', italic: true},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: ' + C'}
      ]
    },
    {
      children: [
        {text: '∫', fontSize: 'largest', bold: true},
        {fontSize: 'largest', text: ' '},
        {fontSize: 'largest', text: 'cos', color: 'blue', italic: true},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: '·'},
        {fontSize: 'largest', text: 'sin', color: 'blue', italic: true},
        {fontSize: 'largest', text: '2x', bold: true},
        {fontSize: 'largest', text: ' '},
        {fontSize: 'largest', text: 'd', italic: true, bold: true},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: ' = -⅔'},
        {fontSize: 'largest', text: 'cos', color: 'blue', italic: true},
        {fontSize: 'largest', text: '³'},
        {fontSize: 'largest', text: 'x', bold: true},
        {fontSize: 'largest', text: ' + C'}
      ]
    }
  ];
}
