import React, {useCallback, useState} from 'react';
import {Value} from '@englex/slate';
import {type Plugin} from '@englex/slate-react';

import EnlxSlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import FontSizeToolbar from 'components/Slate/SlateEditor/plugins/button/FontSize';
import Image from 'components/Slate/SlateEditor/plugins/button/Image';
import ImageButton from 'components/Slate/SlateEditor/plugins/button/Image/ImageButton';

import {BlockFloat, type SlateReactChange} from '../../components/Slate/interface';
import Undo from '../../components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from '../../components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from '../../components/Slate/SlateEditor/plugins/button/Bold';
import Italic from '../../components/Slate/SlateEditor/plugins/button/Italic';
import Underline from '../../components/Slate/SlateEditor/plugins/button/Underline';
import StrikeThrough from '../../components/Slate/SlateEditor/plugins/button/StrikeThrough';
import FontColorToolbar from '../../components/Slate/SlateEditor/plugins/button/Color';
import HighlightToolbar from '../../components/Slate/SlateEditor/plugins/button/Highlight';
import CharSelector from '../../components/Slate/SlateEditor/plugins/button/CharSelector';
import Link from '../../components/Slate/SlateEditor/plugins/button/Link';
import FormatPainter from '../../components/Slate/SlateEditor/plugins/button/FormatPainter';
import ClearFormatting from '../../components/Slate/SlateEditor/plugins/button/ClearFormatting';
import TextAlignment from '../../components/Slate/SlateEditor/plugins/button/TextAlignment';
import {NestedLists} from '../../components/Slate/SlateEditor/plugins/button/List/NestedLists';
import AddDialogBtn from '../../components/Slate/SlateEditor/plugins/button/Table/Dialog/AddDialogBtn';
import {ToggleExample} from '../../components/Slate/SlateEditor/plugins/button/Example/ToggleExample';
import {slateMigrateDown} from '../../components/SlateJS/utils';
import {testIntegralsValue} from '../../components/SlateJS/utils/temp/integrals';

const enlxPlugins: Plugin[] = [
  new Undo(),
  new Redo(),
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new TextAlignment(),
  new FontSizeToolbar(),
  new FontColorToolbar(),
  new HighlightToolbar(),
  new NestedLists(),
  new AddDialogBtn(),
  new CharSelector(),
  new Image({defaultFloat: BlockFloat.CENTER}),
  new ImageButton(),
  new Link(),
  new FormatPainter(),
  new ClearFormatting(),
  new ToggleExample()
];

export const SandboxSlateOld = () => {
  const [enlxSlateValue, setEnlxSlateValue] = useState<Value>(() => {
    return Value.fromJSON(slateMigrateDown(testIntegralsValue()));
  });
  const onEnlxSlateChange = useCallback(({value}: SlateReactChange) => {
    setEnlxSlateValue(value);
  }, []);

  return (
    <div className="sandbox-page">
      <div
        className="sandbox-body"
        style={{
          position: 'relative',
          overflow: 'initial',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{overflow: 'scroll', padding: '50px 20px 20px'}}>
          <EnlxSlateEditor
            toolbar={{portalId: 'sandbox-slate-toolbar-portal'}}
            placeholder="Enter text here!"
            onChange={onEnlxSlateChange}
            value={enlxSlateValue}
            plugins={enlxPlugins}
          />
        </div>
        <div
          id="sandbox-slate-toolbar-portal"
          style={{
            // minHeight: '30px',
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundColor: '#fff',
            order: -1,
            zIndex: 0,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)'
          }}
        />
      </div>
    </div>
  );
};
