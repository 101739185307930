import React, {useCallback, useState} from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import {isTouchEmulatorEnabled, toggleTouchEmulator} from '../../../components/TouchEmulator';

export const ToggleTouchEmulator: React.FC = () => {
  const [isEnabled, setIsEnabled] = useState(isTouchEmulatorEnabled());

  const toggle = useCallback(() => {
    toggleTouchEmulator();
    setIsEnabled(prevState => !prevState);
  }, []);

  return (
    <div>
      <FormGroup>
        <Checkbox onChange={toggle} checked={isEnabled}>
          <span>Touch Emulator Enabled</span>
        </Checkbox>
      </FormGroup>
    </div>
  );
};
