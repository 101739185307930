import {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {downloadUri} from '../../../helpers/download';
import './I18nPanel.scss';

export const I18nPanel: FC = () => {
  const version = useSelector((s: AppState) => s.common.version);
  const downloadRu = async () => {
    const json = (await import(/* webpackChunkName: "i18n-ru-RU" */ 'i18n/translations/ru-RU.json'))
      .default;
    const prettyJson = JSON.stringify(json, null, 2);
    const uri = 'data:text/json;charset=utf-8,' + encodeURIComponent(prettyJson);
    const fileName = `ru-RU-${version}-${new Date().toISOString()}.json`;
    downloadUri(uri, fileName);
  };

  const downloadEn = async () => {
    const json = (await import(/* webpackChunkName: "i18n-en-US" */ 'i18n/translations/en-US.json'))
      .default;
    const prettyJson = JSON.stringify(json, null, 2);
    const uri = 'data:text/json;charset=utf-8,' + encodeURIComponent(prettyJson);
    const fileName = `en-US-${version}-${new Date().toISOString()}.json`;
    downloadUri(uri, fileName);
  };
  return (
    <div className="i18n-panel">
      <Button bsStyle="info" onClick={downloadRu}>
        Download ru-RU
      </Button>
      <Button bsStyle="info" onClick={downloadEn}>
        Download en-US
      </Button>
    </div>
  );
};
