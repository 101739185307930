import React from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Row from 'react-bootstrap/lib/Row';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import ReactSelect from '../../../components/ReactSelect/ReactSelect';
import {type SelectCallback} from '../../../components/interface';
import {type OptionsType, type OptionType} from '../../../components/ReactSelect/interface';

const dropDownItems = ['First Item', 'Second Item', 'Third Item', 'Fourth Item'];

const colorOptions: OptionType[] = [
  {value: 'ocean', label: 'Ocean'},
  {value: 'blue', label: 'Blue'},
  {value: 'purple', label: 'Purple'},
  {value: 'red', label: 'Red'},
  {value: 'orange', label: 'Orange'},
  {value: 'yellow', label: 'Yellow'},
  {value: 'green', label: 'Green'},
  {value: 'forest', label: 'Forest'},
  {value: 'slate', label: 'Slate'},
  {value: 'silver', label: 'Silver'}
];

interface State {
  suggestValue: string;
  dropdownValue?: number;
  isDisabled: boolean;
  selectValue: OptionsType<OptionType>;
}

class Forms extends React.Component<WrappedComponentProps, State> {
  public state: State = {
    suggestValue: '',
    isDisabled: false,
    selectValue: []
  };

  public render() {
    return (
      <div>
        <form className="sandbox-forms">
          <Row>
            <Col sm={4}>
              <FormGroup>
                <ControlLabel>FormControl lg:</ControlLabel>
                <FormControl
                  placeholder="Lorem ipsum dolor"
                  bsSize="lg"
                  disabled={this.state.isDisabled}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropDown lg:</ControlLabel>
                <DropdownButton
                  onSelect={
                    ((eventKey: number) =>
                      this.setState({dropdownValue: eventKey})) as SelectCallback
                  }
                  title={this.renderDropdownTitle()}
                  id="dropdown-basic-5"
                  bsSize="lg"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropUp lg:</ControlLabel>
                <DropdownButton
                  onSelect={
                    ((eventKey: number) =>
                      this.setState({dropdownValue: eventKey})) as SelectCallback
                  }
                  title={this.renderDropdownTitle()}
                  dropup={true}
                  id="dropdown-basic-6"
                  bsSize="lg"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>ReactSelect lg:</ControlLabel>
                <ReactSelect
                  onChange={this.handleChange}
                  options={colorOptions}
                  isDisabled={this.state.isDisabled}
                  isValueSelected={(option: OptionType) =>
                    !!this.state.selectValue.find(({value}: OptionType) => option.value === value)
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <ControlLabel>FormControl:</ControlLabel>
                <FormControl placeholder="Lorem ipsum dolor" disabled={this.state.isDisabled} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropDown:</ControlLabel>
                <DropdownButton
                  onSelect={
                    ((eventKey: number) =>
                      this.setState({dropdownValue: eventKey})) as SelectCallback
                  }
                  title={this.renderDropdownTitle()}
                  id="dropdown-basic-1"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropUp:</ControlLabel>
                <DropdownButton
                  onSelect={
                    ((eventKey: number) =>
                      this.setState({dropdownValue: eventKey})) as SelectCallback
                  }
                  title={this.renderDropdownTitle()}
                  dropup={true}
                  id="dropdown-basic-2"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>ReactSelect:</ControlLabel>
                <ReactSelect
                  closeMenuOnSelect={false}
                  onChange={this.handleChange}
                  options={colorOptions}
                  isDisabled={this.state.isDisabled}
                  isValueSelected={(option: OptionType) =>
                    !!this.state.selectValue.find(({value}: OptionType) => option.value === value)
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <ControlLabel>FormControl sm:</ControlLabel>
                <FormControl
                  placeholder="Lorem ipsum dolor"
                  bsSize="sm"
                  disabled={this.state.isDisabled}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropDown sm:</ControlLabel>
                <DropdownButton
                  onSelect={
                    ((eventKey: number) =>
                      this.setState({dropdownValue: eventKey})) as SelectCallback
                  }
                  title={this.renderDropdownTitle()}
                  id="dropdown-basic-3"
                  bsSize="sm"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>DropUp sm:</ControlLabel>
                <DropdownButton
                  title={this.renderDropdownTitle()}
                  dropup={true}
                  id="dropdown-basic-4"
                  bsSize="sm"
                  disabled={this.state.isDisabled}
                >
                  {dropDownItems.map(this.renderDropdownItems)}
                </DropdownButton>
              </FormGroup>
              <FormGroup>
                <ControlLabel>ReactSelect sm:</ControlLabel>
                <ReactSelect
                  onChange={this.handleChange}
                  options={colorOptions}
                  isDisabled={this.state.isDisabled}
                  isValueSelected={(option: OptionType) =>
                    !!this.state.selectValue.find(({value}: OptionType) => option.value === value)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Checkbox onChange={() => this.setState({isDisabled: !this.state.isDisabled})}>
            <span>Disable all</span>
          </Checkbox>
        </form>
      </div>
    );
  }

  private renderDropdownTitle = () => (
    <React.Fragment>
      {typeof this.state.dropdownValue !== 'undefined' ? (
        dropDownItems[this.state.dropdownValue]
      ) : (
        <b>Select smth</b>
      )}
    </React.Fragment>
  );

  private renderDropdownItems = (item: string, index: number) => (
    <MenuItem key={index} eventKey={index} active={this.state.dropdownValue === index}>
      {dropDownItems[index]}
    </MenuItem>
  );

  private handleChange = (selectedOptions: OptionsType<OptionType> | null) => {
    this.setState({selectValue: selectedOptions || []});
  };
}

export default injectIntl(Forms);
