import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {captureMessage} from '@sentry/react';
import {useNavigate} from 'react-router-dom';

export const SentryTest: FC = () => {
  const navigate = useNavigate();
  const goSandboxError = () => navigate('error');
  return (
    <div className="sentry-test">
      <h1>Sentry test</h1>
      <Button
        onClick={() => {
          throw new Error('Test exception');
        }}
      >
        Capture exception
      </Button>
      <Button
        onClick={() => {
          captureMessage('Test warning', 'warning');
        }}
      >
        Capture warning
      </Button>
      <Button
        onClick={() =>
          new Promise(() => {
            throw new Error('Test exception in promise');
          })
        }
      >
        Error in promise
      </Button>
      <Button
        onClick={() =>
          new Promise(resolve => resolve(true)).then(() => {
            throw new Error('Test exception in promise handler');
          })
        }
      >
        Error in promise handler
      </Button>
      <Button onClick={goSandboxError}>Test error boundary</Button>
    </div>
  );
};
