import React from 'react';

import GradeSlider from './GradeSlider';

interface State {
  grade: number;
}

class GradeWrapper extends React.Component<{}, State> {
  public state: State = {grade: 70};

  public render() {
    return (
      <GradeSlider
        // Methods
        initialGrade={this.state.grade}
        onClick={this.sliderOnClick}
        onDragEnd={this.sliderOnDragEnd}
        onStepClick={this.sliderOnStepClick}
      />
    );
  }

  private sliderOnStepClick = (value: number) => {
    this.setState({grade: value});
  };

  private sliderOnClick = (value: number) => {
    this.setState({grade: value});
  };

  private sliderOnDragEnd = (value: number) => {
    this.setState({grade: value});
  };
}

export default GradeWrapper;
