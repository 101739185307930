import React, {Component, type FC, type SyntheticEvent} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Col from 'react-bootstrap/lib/Col';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Form from 'react-bootstrap/lib/Form';
import {default as FormControl, type FormControlProps} from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Row from 'react-bootstrap/lib/Row';
import SplitButton from 'react-bootstrap/lib/SplitButton';

import {type SelectCallback} from 'components/interface';
import {type WampActionType} from 'store/interface';
import uriMap from 'services/wamp/uriMap';

import {type DispatchProps, type StateProps} from '../index';
import './autobahn-tester.scss';

interface Props extends StateProps, DispatchProps {}

const defaultUrl = import.meta.env.REACT_APP_WAMP_URL;

interface State {
  prefixIndex: number;
}

class Autobahn extends Component<Props, State> {
  private args: HTMLInputElement;
  private kwargs: HTMLInputElement;
  private options: HTMLInputElement;
  private uri: HTMLInputElement;
  private url: HTMLInputElement;
  private uriPrefixes: string[];

  constructor(props: Props) {
    super(props);

    const prefix = import.meta.env.REACT_APP_WAMP_URI_PREFIX;
    this.uriPrefixes = [`${prefix}.`];
    this.uriPrefixes = this.uriPrefixes.concat(Object.keys(uriMap).map(p => `${p}:`));
    this.state = {
      prefixIndex: 0
    };
  }

  public render() {
    const {connected, userToken = '', isChangeTokenDisabled} = this.props;
    const connectBtnText = connected ? 'Disconnect' : 'Connect';
    const connectBtnClass = connected ? 'danger' : 'primary';
    const {RequestBlock} = this;
    const {subscriptions, ...clientState} = this.props.clientState;
    return (
      <div className="autobahn-tester">
        <Form>
          <Row>
            <Col sm={4}>
              <ControlLabel>Client State:</ControlLabel>
              <pre>{JSON.stringify(clientState, null, 2)}</pre>
            </Col>
            <Col sm={8}>
              <Row>
                <Col sm={8}>
                  <FormGroup>
                    <ControlLabel>Router Endpoint:</ControlLabel>
                    <FormControl
                      bsSize="lg"
                      type="text"
                      defaultValue={defaultUrl}
                      disabled={connected}
                      inputRef={url => (this.url = url)}
                    />
                  </FormGroup>
                </Col>
                <Col sm={4}>
                  <FormGroup>
                    <ControlLabel>{'\u00A0'}</ControlLabel>
                    <Button
                      style={{width: '100%'}}
                      bsSize="lg"
                      bsStyle={connectBtnClass}
                      onClick={this.onConnect}
                    >
                      {connectBtnText}
                    </Button>
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <ControlLabel>Token:</ControlLabel>
                    <FormControl
                      bsSize="lg"
                      type="text"
                      value={userToken}
                      disabled={isChangeTokenDisabled}
                      onChange={this.onChangeToken}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <RequestBlock />
        </Form>
      </div>
    );
  }

  private onClick = (e: SyntheticEvent<SplitButton>) => {
    this.props.sendAction(
      this.props.active,
      `${this.prefix}${this.uri.value}`,
      this.args ? JSON.parse(this.args.value) : undefined,
      this.kwargs ? JSON.parse(this.kwargs.value) : undefined,
      this.options ? JSON.parse(this.options.value) : undefined
    );
  };

  private onConnect = (e: SyntheticEvent<Button>) => {
    if (!this.props.connected) {
      this.props.updateToken(this.props.userToken);
      this.props.connect(this.url.value);
    } else {
      this.props.disconnect();
    }
  };

  private onSelect: SelectCallback = (eventKey: {}) => {
    this.props.changeTestAction(eventKey as WampActionType);
  };

  private CallResponse = () => {
    const {response} = this.props;
    return response ? (
      <FormGroup>
        <h4>Response</h4>
        <pre>{response}</pre>
      </FormGroup>
    ) : null;
  };

  private Topics = () => {
    const {topics} = this.props;
    const topicsKeys = Object.keys(topics);
    return topicsKeys.length > 0 ? (
      <div className="topics">
        <h4>Subscriptions</h4>
        {topicsKeys.map((item, i) => {
          return (
            <FormGroup key={i}>
              <ControlLabel>
                {item} ({topics[item].length})
              </ControlLabel>
              <pre className="events">
                {topics[item]
                  .map(topic => {
                    return topic.created + '\n' + topic.json;
                  })
                  .join('\n')}
              </pre>
            </FormGroup>
          );
        })}
      </div>
    ) : null;
  };

  private RequestBlock = () => {
    const {active, connected} = this.props;
    const {CallResponse, Textarea, Topics} = this;
    const actions: WampActionType[] = ['call', 'publish', 'subscribe', 'unsubscribe'];
    const {UriPrefix} = this;
    return connected ? (
      <>
        <FormGroup>
          <h4>Request</h4>
          <ControlLabel>URI:</ControlLabel>
          <Row>
            <Col sm={9}>
              <InputGroup className="uri-input-group">
                <UriPrefix />
                <FormControl
                  bsSize="lg"
                  type="text"
                  defaultValue="chat.rtc.browser-check"
                  inputRef={uri => (this.uri = uri)}
                />
              </InputGroup>
            </Col>
            <Col sm={3} className="action-btn-col">
              <SplitButton
                onClick={this.onClick}
                bsSize="lg"
                title={active}
                id="action-change"
                onSelect={this.onSelect}
              >
                {actions.map(action => {
                  return (
                    <MenuItem key={action} eventKey={action} active={active === action}>
                      {action}
                    </MenuItem>
                  );
                })}
              </SplitButton>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Textarea entity="args" />
            <Textarea entity="kwargs" />
            <Textarea entity="options" />
          </Row>
        </FormGroup>
        <CallResponse />
        <Topics />
      </>
    ) : null;
  };

  private Textarea: FC<{entity: string}> = ({entity}) => {
    const defaultValue = entity === 'args' ? '[]' : '{}';

    const {active} = this.props;
    if (active !== 'call' && active !== 'publish') {
      if (active === 'unsubscribe' || entity !== 'options') {
        return null;
      }
    }

    return (
      <Col sm={4}>
        <ControlLabel>{`${entity[0].toUpperCase()}${entity.slice(1)}`}</ControlLabel>
        <FormControl
          componentClass="textarea"
          inputRef={textarea => (this[entity] = textarea)}
          defaultValue={defaultValue}
        />
      </Col>
    );
  };

  private onChangeToken = (e: SyntheticEvent<FormControlProps>) => {
    const value = String(e.currentTarget.value);
    const {updateToken} = this.props;
    updateToken(value);
  };

  private UriPrefix: FC = () => {
    return (
      <DropdownButton
        noCaret={true}
        bsSize="lg"
        componentClass={InputGroup.Button}
        id="uri-input-dropdown-addon"
        title={this.prefix}
        onSelect={this.onPrefixSelected as SelectCallback}
      >
        {this.uriPrefixes.map((prefix: string, key: number) => {
          return (
            <MenuItem key={key} eventKey={key} active={this.state.prefixIndex === key}>
              {prefix}
            </MenuItem>
          );
        })}
      </DropdownButton>
    );
  };

  private onPrefixSelected = (k: number) => this.setState({prefixIndex: k});

  private get prefix(): string {
    return this.uriPrefixes[this.state.prefixIndex];
  }
}

export default Autobahn;
