import {connect, type MapStateToProps} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState, type Locale} from 'store/interface';

import LanguageDropdown from '../components/LanguageDropdown';
import {changeLocale} from '../actions/actions';

export interface LanguageDropdownStateProps {
  locale: Locale;
  loading: boolean | undefined;
}

export interface LanguageDropdownDispatchProps {
  onChange(locale: Locale): void;
}

export interface LanguageDropdownOwnProps {
  languages: {
    [key: string]: string;
  };
}

const mapStateToProps: MapStateToProps<LanguageDropdownStateProps, {}, AppState> = (
  state: AppState
) => {
  return {
    loading: state.intl.loading,
    locale: state.intl.locale
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>) => ({
  onChange: (locale: Locale) => dispatch(changeLocale(locale))
});

export default connect<
  LanguageDropdownStateProps,
  LanguageDropdownDispatchProps,
  LanguageDropdownOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(LanguageDropdown);
